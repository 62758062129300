import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles, Typography } from '@material-ui/core/';
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import SearchMenu from '../SearchMenu/SearchMenu';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  banner_container: {
    position: 'relative',
    backgroundColor: palette.coreBlue,
    zIndex: '0',
    fontFamily: 'Open Sans',
    display: 'block',
    [breakpoints.up('lg')]: {
      opacity: 1,
      marginBottom: '0px',
      transition: 'height 0.6s, opacity 0.3s',
      overflow: 'hidden',
    },
    '& div': {
      transition: 'opacity 0.3s',
    },
  },

  banner_image: {
    display: 'none',
    objectFit: 'cover',
    [breakpoints.up('lg')]: {
      height: '625px',
      display: 'block',
    },
  },

  text_container: {
    height: '100%',
    zIndex: '1',
    [breakpoints.up('lg')]: {
      backgroundColor: palette.coreYellow,
      position: 'absolute',
      top: '0',
      left: '200px',
      display: 'flex',
      flexDirection: 'column',
      width: '510px',
    },
  },
  headings: {
    padding: '10px',
    backgroundColor: palette.coreBlue,
    [breakpoints.up('lg')]: {
      padding: '14px 26px',
    },
  },
  helper_text: {
    color: palette.coreYellow,
    textDecoration: 'underline',
    display: 'flex',
    '& img': {
      float: 'left',
      paddingRight: '5px',
    },
    [breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  helper_icon: {
    marginRight: '5px',
    width: '20px',
  },
  header: {
    color: 'white',
    display: 'block',
    margin: '0',
  },
  image_container: {
    display: 'none',
    [breakpoints.up('lg')]: {
      display: 'inline-block',
      width: '100%',
      maxHeight: 'inherit',
      overflow: 'hidden',
      height: '100%',
    },
  },
}));

const Banner = ({ location }) => {
  const classes = useStyles();

  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allWordpressWpMedia(
          filter: { source_url: { regex: "/home-page-banner/" } }
        ) {
          edges {
            node {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 10000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  const randomBannerImage = () => {
    const randomNumber = Math.floor(
      Math.random() * data.allWordpressWpMedia.edges.length,
    );
    return data.allWordpressWpMedia.edges[randomNumber].node.localFile
      .childImageSharp.fluid;
  };

  return (
    <div className={`${classes.banner_container}`}>
      <Img
        fluid={randomBannerImage()}
        alt="Banner image"
        className={classes.banner_image}
      />
      <div className={classes.text_container}>
        <div className={classes.headings}>
          <div
            className={classes.helper_text}
            onClick={() => scrollTo('#whatIsCCM')}
            onKeyPress={() => scrollTo('#whatIsCCM')}
            role="button"
            tabIndex="0"
          >
            <Typography variant="body1">What is Cancer Care Map?</Typography>
          </div>
          <Typography variant="h4" className={classes.header}>
            Find cancer support services near you
          </Typography>
        </div>
        <div>
          <SearchMenu location={location} />
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      keywords: PropTypes.arrayOf(PropTypes.string),
      postcode: PropTypes.string,
      radius: PropTypes.number,
      city: PropTypes.string,
      region: PropTypes.string,
      serviceType: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default Banner;
