import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import Parser from 'html-react-parser';
import Layout from '../components/layout';
import mapToComponent from '../utils/mapToComponent';
import Banner from '../components/Banner/Banner';
import SEO from '../components/seo';
import LatestBlogPosts from '../components/LatestBlogPosts/LatestBlogPosts';

export const query = graphql`
  query HomepageQeury {
    wordpressPage(slug: { eq: "homepage" }) {
      title
      content
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '32px 20px 90px',
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      padding: '0 200px',
    },
    '& .wp-block-buttons': {
      marginBottom: '16px',
    },
    '& .wp-block-column': {
      height: 'auto',
      marginTop: '16px',
      marginBottom: '16px',
      padding: '16px,',
    },
    '& .wp-block-columns': {
      '& img': {
        marginTop: '-14px',
        maxWidth: '53px',
        maxHeight: '53px',
        minWidth: '36px',
        minHeight: '36px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        '& .wp-block-column': {
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '100%',
          maxWidth: '100%!important',

          '& .wp-block-columns': {
            flexDirection: 'row',
            '& img': {
              marginTop: '-6px',
            },
          },
        },
      },
    },

    '& hr': {
      margin: '32px 0px',
    },
  },
}));

const Homepage = ({ data, location }) => {
  const classes = useStyles();
  const page = data.wordpressPage;
  /* eslint-disable no-param-reassign */
  const handleComponents = (domNode) => {
    if (!domNode.attribs || domNode.attribs.class !== 'wp-block-columns') {
      return mapToComponent(domNode);
    }

    domNode.children.forEach((child) => {
      domNode.attribs.style = 'display:flex;';
      if (child.attribs && child.attribs.class === 'wp-block-column') {
        if (child.attribs.style === 'flex-basis:33.33%') {
          child.attribs.style = 'flex-basis:10%; margin-right: 16px;';
        } else if (child.attribs.style === 'flex-basis:66.66%') {
          child.attribs.style = 'flex-basis:90%;';
        } else {
          child.attribs.style = 'max-width: 50%;';
        }
      }
    });

    return domNode;
  };
  /* eslint-enable no-param-reassign */

  const content = Parser(page.content, {
    replace: (domNode) => handleComponents(domNode),
  });

  return (
    <Layout>
      <SEO title={page.title} />
      <Banner location={location} />
      <div id="whatIsCCM" className={classes.content}>
        {page.content && <div>{content}</div>}
      </div>
      <LatestBlogPosts />
    </Layout>
  );
};

Homepage.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      keywords: PropTypes.string,
      postcode: PropTypes.string,
      radius: PropTypes.number,
      city: PropTypes.string,
      region: PropTypes.string,
      serviceType: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default Homepage;
